import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Editor } from '@tinymce/tinymce-react'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { styles as translationTabStyles } from 'components/form/fields/translation/TranslationTab.styles'

import 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/skins/ui/oxide/content.css'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/bbcode'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/legacyoutput'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/spellchecker'
import 'tinymce/plugins/tabfocus'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/paste'
// eslint-disable-next-line no-undef
import '_lib/tinymce/pl'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
  },
  label: {
    position: 'relative',
    marginBottom: 15,
    transform: 'none',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const TinymceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  formWidth = 300,
  translationLang = null,
}) => {
  const [id] = useState(randomHash())
  const theme = useTheme()

  const handleChange = content => {
    setValue(name, content)
    validateField(content)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  const footnote = (editor) => {
    editor.windowManager.open({
      title: 'Wstaw przypis',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'number',
            label: 'Numer'
          },
          {
            type: 'input',
            name: 'title',
            label: 'Tytuł'
          },
          {
            type: 'textarea',
            name: 'content',
            label: 'Treść'
          }
        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Anuluj'
        },
        {
          type: 'submit',
          text: 'Wstaw',
          primary: true
        }
      ],
      onSubmit: function (api) {
        const data = api.getData();
        const footnoteContent = `<span class="footnote" data-number="${data.number}" data-title="${data.title}" data-content="${data.content}">
            <sup class="footnote-number" data-number="${data.number}">[${data.number}]</sup>
            <span class="footnote-content" style="display: none;">
            <span class="footnote-content-number">${data.number}</span>
            <span>
                <strong class="footnote-content-title">${data.title} </strong> <span class="footnote-content-content">${data.content}</span>
            </span>
            </span>
          </span> `;
        editor.insertContent(footnoteContent);
        api.close();
      }
    });
  }

  const openFootnoteDialog = (footnoteData, editor, footnoteElement) => {
    editor.windowManager.open({
      title: 'Edytuj przypis',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'number',
            label: 'Numer',
            value: Number(footnoteData.number)
          },
          {
            type: 'input',
            name: 'title',
            label: 'Tytuł',
            value: 'tytryty',
          },
          {
            type: 'textarea',
            name: 'content',
            label: 'Treść',
            value: footnoteData.content
          }
        ],
      },
      initialData: footnoteData,
      buttons: [
        {
          type: 'cancel',
          text: 'Anuluj'
        },
        {
          type: 'submit',
          text: 'Zapisz',
          primary: true
        }
      ],
      onSubmit: function (api) {
        if (footnoteElement) {
          // Usuń element przypisu
          footnoteElement.remove();
        }
        const data = api.getData();
        const footnoteContent = `<span class="footnote" data-number="${data.number}" data-title="${data.title}" data-content="${data.content}">
            <sup class="footnote-number" data-number="${data.number}">[${data.number}]</sup>
            <span class="footnote-content" style="display: none;">
            <span class="footnote-content-number">${data.number}</span>
            <span>
                <strong class="footnote-content-title">${data.title} </strong> <span class="footnote-content-content">${data.content}</span>
            </span>
            </span>
          </span> `;
        editor.insertContent(footnoteContent);
        api.close();
      }
    });
  }

  return (
    <FormControl
      error={renderError && !!error}
      required={validators && validators.includes('required')}
      disabled={disabled}
      className={classes.root}
    >
      <InputLabel htmlFor={id} className={classes.label}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <Editor
        disabled={disabled}
        id={id}
        init={{
          menubar: 'file edit view insert table',
          force_br_newlines: false,
          force_p_newlines: true,
          forced_root_block: 'p',
          entity_encoding: 'raw',
          language: process.env.REACT_APP_LOCALE,
          paste_as_text: true,
          image_advtab: true,
          plugins: [
            'autolink lists link charmap print preview anchor table',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime paste',
          ],
          contextmenu: 'link | copy paste',
          toolbar:
            'insertfile undo redo | styleselect | ' +
            'bold italic | alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | caption link unlink charmap | insertFootnote',
          extended_valid_elements:
            'span[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|' +
            'onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|' +
            'onkeydown|onkeyup],script[charset|defer|language|src|type]',
          style_formats: [
            {
              title: 'Inline',
              items: [
                { title: 'Bold', icon: 'bold', inline: 'strong' },
                { title: 'Italic', icon: 'italic', inline: 'em' },
                {
                  title: 'Underline',
                  icon: 'underline',
                  inline: 'span',
                  styles: { 'text-decoration': 'underline' },
                },
                {
                  title: 'Strikethrough',
                  icon: 'strikethrough',
                  inline: 'span',
                  styles: { 'text-decoration': 'line-through' },
                },
                { title: 'Superscript', icon: 'superscript', inline: 'sup' },
                { title: 'Subscript', icon: 'subscript', inline: 'sub' },
                { title: 'Code', icon: 'code', inline: 'code' },
              ],
            },
            {
              title: 'Blocks',
              items: [
                {
                  title: 'Subtitle 1',
                  block: 'h4',
                  classes: 'h4 font-serif mb-3xs',
                },
                {
                  title: 'Subtitle 2',
                  block: 'h5',
                  classes: 'uppercase tracking-[0.06em] font-bold',
                },
                {
                  title: 'Lead',
                  block: 'div',
                  classes: 'lead',
                },
                { title: 'Paragraph', block: 'p' },
                {
                  title: 'Blockquote',
                  block: 'blockquote',
                  classes: 'shortquote',
                },
                {
                  title: 'Shortquote',
                  block: 'blockquote',
                  classes: 'shortquote',
                },
                { title: 'Div', block: 'div' },
                { title: 'Pre', block: 'pre' },
              ],
            },
            {
              title: 'Alignment',
              items: [
                {
                  title: 'Left',
                  icon: 'alignleft',
                  block: 'p',
                  styles: { 'text-align': 'left' },
                },
                {
                  title: 'Center',
                  icon: 'aligncenter',
                  block: 'p',
                  styles: { 'text-align': 'center' },
                },
                {
                  title: 'Right',
                  icon: 'alignright',
                  block: 'p',
                  styles: { 'text-align': 'right' },
                },
                {
                  title: 'Justify',
                  icon: 'alignjustify',
                  block: 'p',
                  styles: { 'text-align': 'justify' },
                },
              ],
            },
          ],
          paste_text_sticky: true,
          paste_text_sticky_default: true,
          browser_spellcheck: true,
          width:
            formWidth -
            (translationLang &&
              translationTabStyles.root?.paddingLeft +
                translationTabStyles.root?.paddingRight),
          height: 300,
          skin: theme.palette.type === 'dark' ? 'oxide-dark' : '',
          content_css: theme.palette.type === 'dark' ? 'oxide-dark' : '',
          convert_urls: false,
          setup: (editor) => {
            editor.ui.registry.addButton('insertFootnote', {
              icon: 'comment',
              text: 'Przypis',
              onAction: function () {
                footnote(editor)
              }
            });
            editor.on('click', function(e) {
              // Sprawdź, czy kliknięty element lub jego rodzic jest elementem przypisu
              const clickedElement = e.target;
              const footnoteElement = clickedElement.closest('.footnote');

              if (footnoteElement) {
                // Jeśli tak, otwórz okno dialogowe z formularzem przypisu
                // Załóżmy, że funkcja `openFootnoteDialog` otwiera formularz przypisu
                // i może opcjonalnie przyjąć dane do wstępnego wypełnienia formularza
                const footnoteData = {
                  number: footnoteElement.querySelector('.footnote-content-number').innerText,
                  title: footnoteElement.querySelector('.footnote-content-title').innerText,
                  content: footnoteElement.querySelector('.footnote-content-content').innerText,
                };

                openFootnoteDialog(footnoteData, editor, footnoteElement);
              }
            });
            },
        }}
        textareaName={name}
        value={value}
        onEditorChange={handleChange}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </FormControl>
  )
}

TinymceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translationLang: PropTypes.string,
}
