import { LANGS_IRI } from '_lib/langs'
import { exhibitionRelatedEvents as exhibitionRelatedEventsBlock } from './_blocks/_exhibitionRelatedEvents'
import { exhibitionRelatedExhibitions as exhibitionRelatedExhibitionsBlock } from './_blocks/_exhibitionRelatedExhibitions'
import { exhibitionExhibits as exhibitionExhibitsBlock } from './_blocks/_exhibitionExhibits'
import { exhibitionArtists as exhibitionArtistsBlock } from './_blocks/_exhibitionArtists'
import { exhibitionImages as exhibitionImagesBlock } from './_blocks/_exhibitionImages'
import { exhibitionImageCarousel as exhibitionImageCarouselBlock } from './_blocks/_exhibitionImageCarousel'
import { exhibitionImageGallery as exhibitionImageGalleryBlock } from './_blocks/_exhibitionImageGallery'
import { exhibitionSingleExhibit as exhibitionSingleExhibitBlock } from './_blocks/_exhibitionSingleExhibit'
import mediaSchema from '_schema/media'
import eventSchema from '_schema/event'
import exhibitionStatesSchema from '_schema/_enums/exhibitionState'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import _block from './_block'
import ageRestrictionsSchema from '_schema/_enums/ageRestriction'
// import { eventRelated } from './_blocks/_eventRelated'

const ENDPOINT = '/api/exhibitions'

const schema = {
  endpoint: ENDPOINT,
  access: 'exhibition',
  resource: {
    definition: '#Exhibition',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          subtitle: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          curators: {
            type: FIELD_TYPES.TINYMCE,
          },
          alt: {},
          // showMoreLink: {
          //   description: 'Link przycisku "pokaż więcej"',
          // },
          tileLeftHeader: {},
          tileRightHeader: {},
          ticketCtaTitle: {},
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/wystawy/:slug',
              [LANGS_IRI.EN]: '/en/exhibitions/:slug',
            },
          },
        },
      },
      openFrom: {
        type: 'date',
      },
      openTo: {
        type: 'date',
      },
      iksorisId: {
        type: FIELD_TYPES.IKSORIS,
        endpoint: '/api/iksoris/events',
        titleAccessor: 'name',
        label1: 'Wydarzenie',
      },
      easyReadPdfFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik pdf easy-to-read',
        endpoint: mediaSchema.endpoint,
        accept: 'application/pdf',
        restrictManagerMimeType: 'application/pdf',
        validate: ['maxSize'],
      },
      audioGuideFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik audioprzewodnika',
        endpoint: mediaSchema.endpoint,
        accept: 'audio/*',
        restrictManagerMimeType: 'audio/',
        validate: ['maxSize'],
      },
      audioDescriptionFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik audiodeskrypcji',
        endpoint: mediaSchema.endpoint,
        accept: 'audio/*',
        restrictManagerMimeType: 'audio/',
        validate: ['maxSize'],
      },
      signLanguageFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik PJM',
        endpoint: mediaSchema.endpoint,
        accept: 'video/*',
        restrictManagerMimeType: 'video/',
        validate: ['maxSize'],
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/exhibition_thumbs',
        },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: { alt: media.translations[LANGS_IRI.PL].alt },
            [LANGS_IRI.EN]: { alt: media.translations[LANGS_IRI.EN].alt },
          })
        },
      },
      branch: {
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: '/api/branches?pagination=false',
      },
      emuseumImportId: {},
      state: {
        description: 'Stan',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: exhibitionStatesSchema.endpoint,
        validate: ['required'],
      },
      ageRestriction: {
        description: 'Ograniczenie wiekowe',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: ageRestrictionsSchema.endpoint,
      },
      // isShowMoreEnabled: {
      //   description: 'Włącz przycisk "pokaż więcej"',
      //   type: FIELD_TYPES.BOOLEAN,
      // },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/exhibition_blocks',
      definition: '#ExhibitionBlock',
      types: exhibition => [
        ..._block('exhibition').types.map(type => {
          const translations = type.properties.translations
          delete type.properties.translations

          return {
            ...type,
            properties: {
              translations: {
                ...translations,
                properties: {
                  menuTitle: {},
                  ...translations.properties,
                },
              },
              ...type.properties,
              isVisibleInMenu: {},
            },
          }
        }),
        exhibitionRelatedEventsBlock(),
        exhibitionRelatedExhibitionsBlock(),
        exhibitionExhibitsBlock(),
        exhibitionArtistsBlock(),
        exhibitionImageGalleryBlock(exhibition),
        exhibitionImageCarouselBlock(exhibition),
        exhibitionImagesBlock(exhibition),
        exhibitionSingleExhibitBlock(exhibition),
        // eventRelated(false, 'exhibition')
      ],
    },
    relatedExhibitions: {
      endpoint: '/api/exhibition_related_exhibitions',
      definition: '#ExhibitionRelatedExhibition',
      properties: {
        exhibition: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          endpoint: ENDPOINT,
          validate: ['required'],
        },
      },
    },
    relatedEvents: {
      endpoint: '/api/exhibition_related_events',
      definition: '#ExhibitionRelatedEvent',
      properties: {
        event: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          endpoint: eventSchema.endpoint,
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
